<template>
  <svg height="20" width="20" viewBox="0 0 20 20" step="10">
    <linearGradient :id="randomId" x1="0" x2="100%" y1="0" y2="0">
      <stop :offset="`${props.fill}%`" stop-color="#3cc3af" stop-opacity="1" />
      <stop :offset="`${props.fill}%`" :stop-color="`#${unfilledColor}`" stop-opacity="1" />
    </linearGradient>
    <polygon :fill="`url(#${randomId})`" points="9.090909090909092,1.0101010101010102,3.0303030303030303,20,18.181818181818183,7.878787878787879,0,7.878787878787879,15.15151515151515,20" />
  </svg>
</template>

<script lang="ts" setup>
const props = defineProps({
  fill: {
    required: true,
    default: 0,
    type: Number
  },
  unfilledColor: {
    type: String,
    default: 'd8d8d8'
  }
})
const randomId = Math.random().toString(36).substring(7)
</script>
